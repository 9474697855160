@use 'mixins';

.ComponentHead {
    padding-left: var(--smallPadding);
    border-bottom: 1px solid rgb(var(--sectionBorder));

    &.margin-bottom {
        margin-bottom: var(--standardPadding);
    }

    :global(.theme-vp) & {
        h3 {
            strong {
                color: rgb(var(--textColor));
            }
        }
    }

    h3:only-child {
        padding-bottom: var(--smallPadding);
        padding-top: var(--smallPadding);

        :global(.theme-vp) & {
            text-transform: initial;

            strong {
                color: rgb(var(--textColor));
            }
        }
    }

    h2 {
        padding-bottom: var(--standardPadding);
        width: fit-content;
        border-bottom: var(--thickerBorderWidth) solid rgb(var(--fourthColor));

        :global(.theme-vi) & {
            @extend %heading-small;

            padding-bottom: var(--smallPadding);
            border-bottom-color: rgb(var(--mainColor));
        }

        &.main-color {
            border-bottom-color: rgb(var(--mainColor));
        }

        &.main-color-lighter {
            border-bottom-color: rgb(var(--mainColorLighter));
        }

        &.second-color {
            border-bottom-color: rgb(var(--secondColorLighter));
        }

        &.third-color {
            border-bottom-color: rgb(var(--thirdColor));
        }

        &.light-border {
            border-bottom-color: rgb(var(--lightSectionBorder));
        }
    }

    a {
        font-size: 1.1em;
        text-decoration: none;
        font-weight: 700;
        float: right;
        padding-bottom: var(--standardPadding);
        line-height: 1.2em;
        margin-top: -2em;

        &:hover {
            cursor: pointer;
        }
    }

    @include mixins.responsive(m, below) {
        margin-top: var(--standardPadding);
    }
}

@use 'sass:math';
@use 'mixins';

%-timeline-heading-stripe {
    display: block;
    position: absolute;
    left: 50%;
    width: 1em;
    height: 3px;
    background: rgb(var(--mainColor));
    content: '';
    transform: translateX(-50%);
}

$-border-offset: math.div(3px, -1);
$-border-midpoint: math.div(3px, 2) * -1;

%-heading-stripe-below {
    &::before {
        @extend %-timeline-heading-stripe;

        top: $-border-offset;
    }
}

%-heading-stripe-above {
    &::after {
        @extend %-timeline-heading-stripe;

        bottom: $-border-offset;
    }
}

$-event-info-width: 2.7em;

.MatchTimeLine {
    position: relative;
    margin: var(--standardPadding);

    // The actual timeline (the vertical ruler)
    .timeline {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;

        hr {
            margin: auto;
            width: 1em;
            height: 0.125em;
        }

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: rgb(var(--linkColor));
                text-decoration: underline;
            }
        }

        :global(.theme-vi) & {
            padding-top: var(--standardPadding);
            padding-bottom: var(--standardPadding);

            hr {
                display: none;
            }

            &::after {
                display: block;
                width: var(--thickerBorderWidth);
                background: rgb(var(--mainColor));
                margin-left: $-border-midpoint;
                z-index: 0;
            }
        }

        // The actual timeline (the vertical ruler)
        &::after {
            content: '';
            position: absolute;
            width: 2px;
            top: 0;
            bottom: 0;
            left: 50%;
            margin-left: -1px;
            background-image: linear-gradient(
                rgb(var(--sectionSeparationBorder)) 70%,
                rgba(255, 255, 255, 0) 0%
            );
            background-position: right;
            background-size: 2px 7px;
            background-repeat: repeat-y;
        }
    }

    .event {
        position: relative;
        width: 50%;
        margin: var(--smallPadding) 0;
        padding: 0 1.55em;
        line-height: 1.5em;
        box-sizing: border-box;

        :global(.theme-vi) & {
            margin: var(--standardPadding) 0;

            &::after {
                display: none;
            }

            &.left,
            &.right {
                transition: initial;

                &::after {
                    display: none;
                }

                .event-content:hover {
                    transform: initial;
                }
            }

            &.left {
                padding-right: var(--largePadding);

                :global(.first-player .Icon) {
                    margin-left: var(--smallPadding);
                }
            }

            &.right {
                padding-left: var(--largePadding);

                :global(.first-player .Icon) {
                    margin-right: var(--smallPadding);
                }
            }
        }

        // The circles on the timeline
        &::after {
            content: attr(data-time-score);
            position: absolute;
            top: 10px;
            right: calc($-event-info-width / -2);
            width: $-event-info-width;
            height: 2em;
            line-height: 1.6em;
            font-weight: 700;
            text-align: center;
            background-color: rgb(var(--invertedBackground));
            color: rgb(var(--invertedTextColor));
            border: 2px solid rgb(var(--sectionSeparationBorder));
            border-radius: 1em;
            direction: ltr;
            z-index: 1;
            font-size: 0.75em;
            box-sizing: border-box;
        }

        &.double-rows::after {
            top: 22px;
        }

        &.left {
            display: block;
            position: relative;
            left: 0;
            direction: rtl;

            > p {
                direction: rtl;
            }

            .event-content:hover {
                transform: translateX(calc(-1 * var(--standardPadding)));
            }
        }

        &.right {
            display: block;
            position: relative;
            left: 50%;

            &::after {
                left: calc($-event-info-width / -2);
            }

            .event-content:hover {
                transform: translateX(var(--standardPadding));
            }
        }

        &.has-overtime {
            &::after {
                right: math.div($-event-info-width * -1.5, 2);
                width: $-event-info-width * 1.5;

                :global(.theme-vi) & {
                    display: none;
                }
            }

            &.right::after {
                left: math.div($-event-info-width * -1.5, 2);

                :global(.theme-vi) & {
                    display: none;
                }
            }
        }
    }

    .event-content {
        padding: var(--standardPadding) 0;
        display: flex;
        align-items: center;
        font-weight: 700;
        background-color: rgb(var(--lightSectionBack));
        transition: 0.2s ease-in-out;

        > p {
            direction: ltr;
        }

        :global(.Icon) {
            font-size: 1.5em;
            margin: 0 var(--smallPadding);
        }

        :global(.second-player) {
            color: rgb(var(--lighterTextColor));
            font-weight: 400;
        }

        :global(.theme-vi) & {
            padding: 0;

            :global(.Icon) {
                margin: initial;
            }

            :global(.first-player) {
                padding: var(--smallPadding);
            }

            :global(.second-player) {
                color: rgb(var(--secondaryTextColor));
                padding: var(--tinyPadding) var(--smallPadding);
                background: rgb(var(--lightSectionBorder));
            }
        }
    }

    .name-rows {
        width: 100%;

        :global(.theme-vi) & {
            @extend %section-shadow;
        }
    }

    h3 {
        position: relative;
        margin: var(--smallPadding);
        padding: 0.5em;
        text-align: center;

        &.sub-header,
        &.first-header,
        &.last-header {
            :global(.theme-vi) & {
                @extend %heading-x-small;

                margin: auto;
                color: rgb(var(--lighterTextColor));
            }
        }

        &.sub-header {
            margin: var(--largePadding);
            background-color: rgb(var(--background));
            font-weight: 700;
            z-index: 1;
            color: rgb(var(--textColor));

            :global(.theme-vi) & {
                @extend %-heading-stripe-above;
                @extend %-heading-stripe-below;

                margin: var(--largePadding) auto;
            }
        }

        :global(.theme-vi) & {
            &.last-header {
                @extend %-heading-stripe-below;
            }

            &.first-header {
                @extend %-heading-stripe-above;
            }
        }
    }
}

@include mixins.responsive(m, below) {
    .MatchTimeLine .event {
        padding: 0;

        &.left {
            padding-right: 1.1em;
        }

        &.right {
            padding-left: 1.1em;
        }
    }
}

/* stylelint-disable scss/dollar-variable-empty-line-before */
@use 'mixins';
@use 'sass:math';

// sass error if em are used: "1.25em and 3px have incompatible units".
$-circle-diameter: 20px;
$-circle-inner-dot-diameter: 8px;
$-circle-clip-path: inset(-3px 50% -3px -3px);

$-border-px: 3px;
$-border-radius: math.div($-circle-diameter + $-border-px, 2);

$-time-x-offset: $-circle-diameter * -1;
$-circle-x-offset: math.div($-circle-diameter, 2) * -1;

%-centered-marker {
    .event-circle {
        &::before {
            clip-path: $-circle-clip-path;
        }

        &::after {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.TimelineMarker {
    display: contents;

    .event-time,
    .event-circle {
        position: absolute;
        top: var(--smallPadding);
    }

    .event-circle {
        width: $-circle-diameter;
        height: $-circle-diameter;
        background-color: rgb(var(--background));
        font-size: 1rem;
        line-height: initial;
        z-index: 1;
        content: '';
        color: rgb(var(--mainColor));

        // Circle border
        &::before {
            position: absolute;
            width: $-circle-diameter;
            height: $-circle-diameter;
            background-color: white;
            line-height: initial;
            z-index: 2;
            content: '';
            color: rgb(var(--mainColor));
            border-radius: $-border-radius;
            outline: 3px solid rgb(var(--mainColor));
        }

        // Dot inside circle
        &::after {
            position: absolute;
            width: $-circle-inner-dot-diameter;
            height: $-circle-inner-dot-diameter;
            background-color: rgb(var(--mainColor));
            line-height: initial;
            z-index: 3;
            content: '';
            color: rgb(var(--mainColor));
            border-radius: $-border-radius;
        }
    }

    .event-time {
        @extend %heading-small;

        transform: translateY(var(--smallPadding));
        color: rgb(var(--mainColor));
    }

    &.left {
        @extend %-centered-marker;

        .event-time {
            left: 100%;
            transform: translateX($-circle-diameter);
        }

        .event-circle {
            right: $-circle-x-offset;
            transform: scaleX(-1);
        }
    }

    &.right {
        @extend %-centered-marker;

        .event-time {
            left: $-time-x-offset;
            transform: translateX(-100%);
        }

        .event-circle {
            left: $-circle-x-offset;
        }
    }
}
